// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-receiving-js": () => import("./../../../src/pages/mail-receiving.js" /* webpackChunkName: "component---src-pages-mail-receiving-js" */),
  "component---src-pages-mail-services-js": () => import("./../../../src/pages/mail-services.js" /* webpackChunkName: "component---src-pages-mail-services-js" */),
  "component---src-pages-packing-shipping-js": () => import("./../../../src/pages/packing-shipping.js" /* webpackChunkName: "component---src-pages-packing-shipping-js" */),
  "component---src-pages-passports-js": () => import("./../../../src/pages/passports.js" /* webpackChunkName: "component---src-pages-passports-js" */),
  "component---src-pages-supplies-js": () => import("./../../../src/pages/supplies.js" /* webpackChunkName: "component---src-pages-supplies-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

